.about-section {
    padding: 50px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .section-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1abc9c;
    margin-bottom: 30px;
  }
  
  .intro-text {
    text-align: unset;
    text-indent: 2rem;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .program-section,
  .why-masjid-section,
  .cta-section {
    margin-bottom: 40px;
  }
  
  .program-title,
  .masjid-title,
  .cta-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #1abc9c;
    margin-bottom: 20px;
  }
  
  .program-text,
  .masjid-text,
  .cta-text {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .cta-button {
    padding: 10px 30px;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    background-color: #1abc9c;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #16a085;
  }
  
  @media (max-width: 768px) {
    .section-title {
      font-size: 2rem;
    }
  
    .intro-text {
      font-size: 1rem;
    }
  
    .program-title,
    .masjid-title,
    .cta-title {
      font-size: 1.5rem;
    }
  }
  