.home-hero-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f5156;
    padding: 80px;
  }
  
  .home-text-section {
    flex: 1;
    max-width: 50%;
    color: white;
  }
  
  .welcome-text{
    font-weight: bold;
    font-style: italic;
    font-size: 5em;
    text-decoration: underline;
    text-shadow: '2px 2px 10px #000, -2px -2px 10px #000';
  }
  .bold-text {
    font-weight: bold;
    font-style: italic;
  }
  
  .gray-text {
    color: white;
  }
  
  .source-text {
    color: white;
    font-style: italic;
  }
  
  .learn-more-button {
    background-color: #ffd700;
    color: #000000;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .home-image-section {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  .mosque-image {
    width: 300px;
    height: auto;
  }
  
