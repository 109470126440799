.home {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .iframe-container {
    max-width: 100vw; 
    height: 100vh;
    position: relative;
    overflow: auto;
    background-color: light-grey;
  }
  
  .iframe-container iframe {
    width: 100%;
    height: 100%;
    transform: scale(1); 
    transform-origin: top left;

    border: none;
  }
  
  .hero-section {
    flex: 1;
  
  }
  